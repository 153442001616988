import React from 'react'
import { persistor, store } from './store'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { Loader } from './components/ui/Loader'
import './style/index.scss'
import './utils/localization/i18n'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider as AntdConfigProvider, notification } from 'antd'
import { defaultTheme } from './style/antdThemeConfig'

// console.log(process.env.REACT_APP_GOOGLE_MAP_API_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <AntdConfigProvider theme={defaultTheme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AntdConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
