import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { FeedItemInterface, FeedItemResponseInterface } from './postsNew'
import { UserInterface } from './userNew'

interface CreateActivityDTO extends FeedItemInterface {
  startDate: Date
}

export interface ActivityExtension {
  startDate: string
}

export type ActivityDTO = FeedItemResponseInterface & ActivityExtension

interface State {}
export const initialState: State = {}
//#region methods

export const attemptPublishActivity = createAsyncThunk<ActivityDTO, CreateActivityDTO>(
  'activities/publish',
  async (payload) => {
    const response = await $api.post(`activity`, payload)
    return response.data
  },
)

//#endregion
//#region slice

export const activitySlice = createSlice({
  name: 'activitySlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = activitySlice.actions
export default activitySlice.reducer
