import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface State {
  isWelcome: boolean
}

export const initialState: State = {
  isWelcome: false,
}

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsWelcome: (state, { payload }: PayloadAction<boolean>) => {
      state.isWelcome = payload
    },
  },
})

export const { setIsWelcome } = appReducer.actions
export default appReducer.reducer
