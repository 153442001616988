import { ReactNode, createContext, useEffect, useRef } from 'react'
import io, { Socket } from 'socket.io-client'
import { socketBaseURL } from '../components/route/indexPathByRole'
import { useAppSelector } from '../utils/hooks'
import { RootState } from '../store'

const SocketContext = createContext<{
  socket: React.MutableRefObject<Socket | null> | null
}>({
  socket: null,
})

const SocketContextProvider = ({ children }: { children: ReactNode }) => {
  let socketRef = useRef<Socket | null>(null)
  const { accessToken } = useAppSelector((state: RootState) => state.auth)
  useEffect(() => {
    socketRef.current = io(socketBaseURL, {
      auth: {
        accessToken: `Bearer ${accessToken}`,
      },
    })

    socketRef.current.on('connect', () => {
      console.log('Сокс-соединение установлено')
    })
  }, [])

  return (
    <SocketContext.Provider
      value={{
        socket: socketRef,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export { SocketContext, SocketContextProvider }
