//TODO: remove this file after finishing profile
import { createSlice } from '@reduxjs/toolkit'
import { LatLng } from 'leaflet'
import { ObjectDate } from '../components/admin-components/users/UserListModule'
import {
  EDUCATION,
  FAMILY_STATUS,
  ORIENTATION,
  PRIVACY,
  QUALITYENUM,
  SEX,
} from '../types/enum'
import { Nullable } from '../types/types'

import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { UserHttp } from '../http/user-http'
import { AddressDTO } from '../components/general-components/location/AddressDTO'

export interface _IdInterface {
  _id: string
}

export interface userIdentityIdInterface {
  userIdentityId: string
}

export interface ProfileInitialStateInterface extends _IdInterface {
  isLoad: boolean

  isLocationVerify: boolean
  isGotAllProfileInfo: boolean

  coordinates: { lat: number; lng: number }
  city: string | null
  radiusSize: number | null
  polygonCoords: LatLng[] | LatLng[][] | LatLng[][][] | null
  country: string | null
  houseNumber: string | null
  street: string | null

  createdUserDate: number // Date can't be serialized, so we store the timestamp
  blockedUserDate: ObjectDate

  step: number

  privacy: PRIVACY

  aboutMe: string
  dateBirth: null | Date
  cityBirth: null | string
  sex: null | SEX
  orientation: ORIENTATION
  familyStatus: null | FAMILY_STATUS

  nationality: string
  professions: { _id: string | number; title: string }[]
  interests: { _id: string | number; title: string }[]
  skills: { _id: string | number; title: string }[]

  certificatesFileName: string[] | []

  studySchool: string
  education: EDUCATION | null

  isAddedServices: boolean
  isAddedPost: boolean
  isExploreDone: boolean
  isMessageWritten: boolean
  isVideoShown: boolean
  isExploreChatsDone: boolean

  lastStepChangeProfile: string

  online: boolean
  onlineDate: number
  address?: AddressDTO
  interestRadius?: number
  interestZone?: any
}

const initCoordinates = {
  lat: 50.440569860389814,
  lng: 30.540884262459286,
}

export type State = ProfileInitialStateInterface & userIdentityIdInterface

export const initialState: State = {
  _id: '',
  isLoad: false,
  userIdentityId: '',

  isLocationVerify: false,
  isGotAllProfileInfo: false,

  coordinates: initCoordinates,
  radiusSize: null,
  polygonCoords: null,
  city: null,
  country: null,
  houseNumber: null,
  street: null,

  createdUserDate: new Date().getTime(),
  blockedUserDate: {
    date: 0,
    hours: 0,
    minutes: 0,
    months: 0,
    seconds: 0,
    years: 0,
    milliseconds: 0,
  },

  step: 5,

  privacy: PRIVACY.EVERYONE,

  aboutMe: '',
  dateBirth: null,
  cityBirth: null,
  sex: null,
  orientation: ORIENTATION.HETERO,
  familyStatus: null,
  nationality: '',

  professions: [],
  interests: [],
  skills: [],

  certificatesFileName: [],

  studySchool: '',
  education: null,

  isAddedServices: false,
  isAddedPost: false,
  isExploreDone: false,
  isMessageWritten: false,
  isVideoShown: false,
  isExploreChatsDone: false,

  lastStepChangeProfile: '/profile',

  online: true,
  onlineDate: new Date().getTime(),
}
//#region methods

export interface LocationPayload {
  coordinates: { lat: number; lng: number }
  city: string | null
  country: string | null
  houseNumber: string | null
  street: string | null
  radiusSize: number | null
  polygonCoords: LatLng[] | LatLng[][] | LatLng[][][] | null
  _id: string
}

interface LocationPayloadResponse {
  isLocationVerify: boolean
}

export interface UserIdentityInterface {
  _id: string

  userIdentityId: string
  isLocationVerify: boolean
  isGotAllProfileInfo: boolean

  coordinates: { lat: number; lng: number }
  radiusSize: number | null
  polygonCoords: LatLng[] | LatLng[][] | LatLng[][][] | null
  city: string | null
  country: string | null
  houseNumber: string | null
  street: string | null

  createdUserDate: Date
  blockedUserDate: ObjectDate

  step: number
  aboutMe: string
  dateBirth: null | Date
  cityBirth: null | string
  sex: null | SEX
  orientation: ORIENTATION
  familyStatus: null | FAMILY_STATUS

  nationality: { _id: string | number; title: string }[]
  professions: { _id: string | number; title: string }[]
  interests: { _id: string | number; title: string }[]
  skills: { _id: string | number; title: string }[]

  certificatesFileName: string[] | []

  studySchool: string
  education: EDUCATION | null

  online: boolean
  onlineDate: Date
}

export const getIdentity = createAsyncThunk<UserIdentityInterface>(
  'profile/get-information',
  async (payload) => {
    const response = await $api.get('/profile')
    return response.data
  },
)

export const profileChangeLocation = createAsyncThunk<
  LocationPayloadResponse,
  LocationPayload
>('identity/profile-location', async (payload) => {
  const response = await UserHttp.changeLocation(payload)
  return response.data
})

export const profileUploadCertificates = async (
  formData: FormData,
): Promise<{ certificatesFileName: string[] }> => {
  try {
    const response = await $api.post('identity/upload-certificates', formData)
    return response.data
  } catch (error) {
    console.log('upload certificates is faild')
    throw error
  }
}

export type RequestInterface = Nullable<ProfileInitialStateInterface> & _IdInterface

export const profileTextInfo = async (
  payload: RequestInterface,
): Promise<Nullable<ProfileInitialStateInterface>> => {
  try {
    const response = await $api.post('identity/profile-text-info', payload)
    return response.data
  } catch (error) {
    console.log(error + 'faild')
    throw error
  }
}

type RequestSelectInterface = {
  value: { _id: string | number; title: string }[]
  quality: QUALITYENUM
} & _IdInterface

export const profilePutIdentity = async (
  payload: RequestSelectInterface,
): Promise<Nullable<ProfileInitialStateInterface>> => {
  try {
    const response = await $api.post('identity/put-profile-identity', payload)
    return response.data
  } catch (error) {
    console.log(error + 'faild')
    throw error
  }
}
//#endregion
export const profileReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setValueProfileReducer: (
      state,
      {
        payload,
      }: {
        payload: Nullable<ProfileInitialStateInterface>
      },
    ) => {
      Object.assign(state, payload)
    },
    setLoader: (
      state,
      {
        payload,
      }: {
        payload: boolean
      },
    ) => {
      state.isLoad = payload
    },
    setCoordinates: (
      state,
      {
        payload,
      }: {
        payload: { lat: number; lng: number }
      },
    ) => {
      state.coordinates = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.fulfilled, (state, { payload }) => {
        Object.assign(state, payload)
      })
      .addCase(profileChangeLocation.fulfilled, (state, { payload }) => {
        const { isLocationVerify } = payload
        state.isLocationVerify = isLocationVerify
      })
      .addMatcher(
        (action) => {
          return (
            action.type.endsWith('/pending') ||
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected')
          )
        },
        (state, action) => {
          state.isLoad = action.type.endsWith('/pending')
          state.coordinates =
            state.coordinates.lat === null || state.coordinates.lng === null
              ? initCoordinates
              : state.coordinates
        },
      )
  },
})

export const { setValueProfileReducer, setLoader, setCoordinates } =
  profileReducer.actions
export default profileReducer.reducer
