import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { SocketContext } from '../../../context/SocketContext'
import { NOTIFICATION_EVENT, SOCKET_NOTIFICATION_EVENT } from '../../../types/enum'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { notificationDirname } from '../../../utils/titles'
import { IconAdminClose } from '../../svg/IconAdminHeader'

export const Notification = ({ children }: { children: ReactNode }) => {
  const { socket } = useContext(SocketContext)
  const { _id } = useAppSelector((state) => state.user)
  const [notification, setNotification] = useState<{
    title: string
    fileName: string
    fullName: string
  } | null>(null)
  let timerRef = useRef<NodeJS.Timeout | null>(null)
  const [dirname, setDirname] = useState('')

  useEffect(() => {
    if (socket && socket?.current && _id) {
      console.log(SOCKET_NOTIFICATION_EVENT.JOIN_ROOM_NOTIFICATION, String(_id))

      socket.current?.emit(SOCKET_NOTIFICATION_EVENT.JOIN_ROOM_NOTIFICATION, String(_id))

      socket.current?.on(
        SOCKET_NOTIFICATION_EVENT.NOTIFICATION,
        (
          ownerId: string,
          title: string,
          fileName: string,
          fullName: string,
          event: NOTIFICATION_EVENT,
        ) => {
          console.log(
            'ownerId',
            ownerId,
            'title',
            title,
            'fileName',
            fileName,
            'fullName',
            fullName,
            'event',
            event,
          )

          if (ownerId === _id) {
            return
          }

          if (
            window.location.pathname.includes('chat') &&
            event === NOTIFICATION_EVENT.NOTIFICATION_MESSAGE
          ) {
            return
          }

          if (timerRef.current) {
            clearTimeout(timerRef.current)
          }

          const dir = notificationDirname(event)
          setDirname(dir)
          setNotification({ title, fileName, fullName })

          timerRef.current = setTimeout(() => {
            setNotification(null)
          }, 70000)
        },
      )
    }
    return () => {
      if (socket && socket?.current) {
        socket.current?.emit(
          SOCKET_NOTIFICATION_EVENT.LEAVE_ROOM_NOTIFICATION,
          String(_id),
        )
      }
    }
  }, [socket?.current, _id])

  const handlerCloseNotification = () => {
    setNotification(null)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      socket?.current?.emit(
        SOCKET_NOTIFICATION_EVENT.LEAVE_ROOM_NOTIFICATION,
        String(_id),
      )
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)

      socket?.current?.emit(
        SOCKET_NOTIFICATION_EVENT.LEAVE_ROOM_NOTIFICATION,
        String(_id),
      )
    }
  }, [socket, _id])

  return (
    <>
      {notification && (
        <div className="notification__alert">
          <div className="notification__alert-img">
            {/* <img
                            src={`${baseURL}/uploads/${dirname}/${notification.fileName}`}
                            alt=""
                        /> */}
            {notification.fileName ? (
              <img src={`${apiBaseURL}/uploads/${dirname}/${notification.fileName}`} />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                  margin: '5px 0',
                }}
              >
                <span style={{ position: 'absolute', left: '14px', top: '9px' }}>
                  {notification.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <h5 className="notification__alert-text">{notification.fullName}</h5>
            <h6 className="notification__alert-text">{notification.title}</h6>
          </div>
          <button
            className="notification__alert-close"
            onClick={handlerCloseNotification}
          >
            <IconAdminClose />
          </button>
        </div>
      )}
      {children}
    </>
  )
}
