import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { InterestZoneDTO } from '../utils/geo.dto'
import { FeedItemInterface, FeedItemResponseInterface } from './postsNew'

export interface ServiceExtension {
  categoryId: string
  subCategoryId: string
  phone: string
  interestZone: InterestZoneDTO
}

type CreateServiceDTO = FeedItemInterface & ServiceExtension

export type ServiceDTO = FeedItemResponseInterface & ServiceExtension

interface State {}
export const initialState: State = {}

export const attemptPublishService = createAsyncThunk<void, CreateServiceDTO>(
  'publishedServices/create',
  async (payload) => {
    // Invert lat et lng
    if (payload.interestZone.polygon) {
      payload.interestZone.polygon.coordinates[0] =
        payload.interestZone.polygon.coordinates[0].map((v) => [v[1], v[0]])
    }

    const response = await $api.post('/service/published', payload)
    return response.data
  },
)

export const publishedServicesReducer = createSlice({
  name: 'publishedServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = publishedServicesReducer.actions
export default publishedServicesReducer.reducer
