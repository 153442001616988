import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Notification } from './components/general-components/notification/Notification'
import { SocketContextProvider } from './context/SocketContext'
import { RootState } from './store'
import { lazy, Suspense } from 'react'
import AppFallback from './components/user-components/AppFallback'
import useSessionExpiration from './utils/useSessionExpiration'

const AppRoutes = lazy(() => import('./AppRoutes'))
export default function App() {
  const isWelcome = useSelector((state: RootState) => state.app.isWelcome)
  const { role } = useSelector((state: RootState) => state.user)
  useSessionExpiration()

  const location = useLocation()

  return (
    <SocketContextProvider>
      <Notification>
        <Suspense fallback={<AppFallback />}>
          <AppRoutes />
        </Suspense>
      </Notification>
    </SocketContextProvider>
  )
}
