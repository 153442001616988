import {
  IconsNotificationStatusEvent,
  IconsNotificationStatusMessage,
  IconsNotificationStatusPost,
  IconsNotificationStatusServices,
} from '../components/svg/IconsNotificationStatus'
import { NOTIFICATION_EVENT } from '../types/enum'

export const toOneKind = (s: string) => s.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()

export const headerTitle = (locationRoute: string | undefined) => {
  const key = String(locationRoute)

  switch (key) {
    case '/admin/users':
      return 'Users'
    case '/admin/users/allusers':
      return 'Users'
    case '/admin/users/coordinators':
      return 'Users'
    case '/admin/users/blocked':
      return 'Users'
    case '/admin/users/groups':
      return 'Users'

    case '/admin/services':
      return 'Services'
    case '/admin/services/services-list-sub':
      return 'Sub Services'

    case '/admin/posts':
      return 'Publications'
    case '/admin/messeges/chat':
      return 'Messenger'
    case '/admin/messeges':
      return 'Messenger'
    case '/admin/helpcenter':
      return 'Help Center'
    case '/admin/advertisement':
      return 'Advertisement'
    case '/admin/activities':
      return 'Activities'
    case '/admin/activities/eventactivities':
      return 'Event'
    case '/admin/adminpanel':
      return 'Admin Panel'
    default:
      return 'Admin Panel'
  }
}

export const userSubTitle = (locationRoute: string | undefined) => {
  const key = String(locationRoute)

  switch (key) {
    case '/admin/users/user':
      return 'Users'
    case '/admin/users/admin':
      return 'Admin'
    case '/admin/users/regionaladmin':
      return 'Regional Admin'
    case '/admin/users/localadmin':
      return 'Local Admin'
    case '/admin/users/coordinators':
      return 'Coordinators'
    case '/admin/users/allusers':
      return 'All Users'
    case '/admin/users/coordinators':
      return 'Coordinators'
    case '/admin/users/blocked':
      return 'Blocked'
    case '/admin/users/techsupport':
      return 'Tech Support'
    default:
      return 'Groups'
  }
}

export const advertiseSubtitle = (locationRoute: string | undefined) => {
  const key = String(locationRoute)

  switch (key) {
    case '/admin/advertisement/regions':
      return 'Regions'
    case '/admin/advertisement/advertisement':
      return 'Advertisement'
    default:
      return 'Advertisement'
  }
}

export const getPublishTitle = (locationRoute: string) => {
  switch (locationRoute) {
    case '/publish/post':
      return 'Create Publication'
    case '/publish/service':
      return 'Provide Service'
    case '/publish/event':
      return 'Organise Event'
    default:
      return ''
  }
}

export const postsSubTitle = (locationRoute: string) => {
  switch (locationRoute) {
    case '/admin/posts/all':
      return 'Post'
    case '/admin/posts/comments':
      return 'Comments'
    case '/admin/posts/reports':
      return 'Reports'
    default:
      return 'Post'
  }
}

export const isNotShowFooterNavUser = (key: string) => {
  if (
    key === '/user/comments'
    // key === '/user/service' ||
    // key === '/user/explore' ||
    // key === '/user/activities' ||
    // key === '/user/messeges' ||
    // key === '/user/messeges/user-chat'
  ) {
    return false
  }
  return true
}

export const notificationDirname = (key: NOTIFICATION_EVENT) => {
  switch (key) {
    case NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES:
      return 'publish_activities'
    case NOTIFICATION_EVENT.NOTIFICATION_MESSAGE:
      return 'avatar'
    case NOTIFICATION_EVENT.NOTIFICATION_NEWS:
      return 'publish_post'
    case NOTIFICATION_EVENT.NOTIFICATION_SERVICE:
      return 'publish_services'
    default:
      return 'avatar'
  }
}

export const notificationTypeTitle = (key: NOTIFICATION_EVENT) => {
  switch (key) {
    case NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES:
      return {
        text: 'added a new activity called',
        label: IconsNotificationStatusEvent,
        link: 'activities',
      }
    case NOTIFICATION_EVENT.NOTIFICATION_MESSAGE:
      return {
        text: 'added a new chat called',
        label: IconsNotificationStatusMessage,
        link: 'messeges',
      }
    case NOTIFICATION_EVENT.NOTIFICATION_NEWS:
      return {
        text: 'added a new post called',
        label: IconsNotificationStatusPost,
        link: 'posts',
      }
    case NOTIFICATION_EVENT.NOTIFICATION_SERVICE:
      return {
        text: 'added a new service called',
        label: IconsNotificationStatusServices,
        link: 'service',
      }
    default:
      return {
        text: 'added a new called',
        label: IconsNotificationStatusMessage,
        link: 'posts',
      }
  }
}
